body {
  overflow: hidden; /* Hide scrollbars */
}

.App {
  text-align: center;
}

:root {
  --offset-start: calc(50vw);
  --offset-end: calc(-50vw);
  --offset-y: 10vw;
  --index: 1;
  --random: 1;
}

.SentenceMove {
  display: inline;
  pointer-events: none;
  white-space: nowrap;
  position: absolute;
  --offset-start: calc(50vw + 1vw * (10 - var(--index)));
  --offset-end: calc(-50vw);
  --offset-y: calc(10% * var(--index));
  top: var(--offset-y);
  transform: translateX(100vw) translateY(var(--offset-y));
}

.Amore {
  position: absolute; 
  top: 15%;
  left: 35%;
  margin-left: -20vw;
  width: 70%;
  height: 80%;
  pointer-events: none;
  object-fit: contain;
}

.Blinking {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .Amore {
    animation: Amore-spin 1 3s linear;
  }
  .SentenceMove {
    animation: SentenceMove-move infinite 5s ease-in-out ;
    animation-direction: alternate;
    animation-duration: calc(var(--random) * 1s + 4s);
    animation-delay: calc(var(--random) * 1s);
    animation-iteration-count: 3;
  }
  .Blinking {
    animation: blinker 1s linear infinite;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  align-items: top;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

@keyframes Amore-spin {
  from {
    transform: rotate(0deg) scale(0.2);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes SentenceMove-move {
  from {
    transform: translateX(var(--offset-start)) translateY(var(--offset-y));
  }
  to {
    transform: translateX(var(--offset-end)) translateY(var(--offset-y));
  }
}